import * as React from 'react';
import { Text, MainContainer, SEO } from '~/components';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const Title = styled(Text)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));

const TextNormal = styled(Text)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

const BottomRow = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  justifyContent: 'center',
}));

function About(props) {
  return (
    <>
      <SEO
        title="Über eus"
        description="Wir sorgen dafür, dass dein Flow nie unterbrochen wird."
      />
      <MainContainer
        css={(theme) => ({
          alignItems: 'center',
          textAlign: 'center',
          color: theme.colors.sand,
        })}
      >
        <h1
          css={(theme) => ({
            color: theme.colors.rose,
            textTransform: 'uppercase',
            textAlign: 'center',
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(2),
          })}
        >
          Über eus
        </h1>
        <TextNormal>
          Wir von «nahschub», sind große Liebhaber guter Tropfen. Am liebsten
          haben wir es, wenn es an spassigen Abenden flüssig läuft, wie bei
          allem im Leben halt. Weil wir selbst es zu oft schon erlebt haben,
          dass es an einem flowigen Abend zu Unterbrechungen kommen kann, wenn
          es darum geht Nachschub zu holen, haben wir «nahschub» gegründet.
        </TextNormal>
        <TextNormal>
          Bei- und von uns, gibt es ein extravagantes Angebot. Wir haben
          qualitativ hochstehenden Gin aus schweizer Produktion, Vodka aus der
          Nachbarschaft, oder einen Shot Hierbas der dich an deine letzte
          Clubnacht im Gonzo, anno 2019 erinnert und vieles mehr.
        </TextNormal>
        <TextNormal>
          Die Cocktails, die wir anbieten, sind sozusagen handverlesen, die
          Zutaten sind, wenn immer möglich, von regionalen Brauereien und
          Destillen. Wir liefern deinen All-Time-Classic, oder auch mal etwas
          sprütziges Neues, um geistig flexibel zu bleiben und nicht völlig
          einzurosten, zu dir nach Hause, oder wenn du dieses doch auch mal
          verlassen solltest, an deinen aktuellen Standort.
        </TextNormal>
        <TextNormal>
          Mit Hilfe unserer Velos sind wir schnell überall in der Stadt Zürich,
          kommen durch jede Einbahnstrasse und schlängeln uns durch
          Trottinettler und Joggerinnen hindurch. Viele Sommer lang haben wir
          gehofft, dass es uns gäbe, aber leider war das bis jetzt noch nicht
          der Fall…
        </TextNormal>
        <TextNormal>
          <span style={{ fontWeight: 'bold' }}>
            Jetzt strampeln wir- für dich,- für euch
          </span>
        </TextNormal>

        <BottomRow>
          <StaticImage
            src="../../static/images/leo_david.JPG"
            css={(theme) => ({
              [theme.breakpoints.up('sm')]: { width: '80%' },
            })}
          />
        </BottomRow>

        <h2>Cheers</h2>
        <h2>Leo & David</h2>
      </MainContainer>
    </>
  );
}

export default About;
